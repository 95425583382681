import { useUser } from '@/auth/user-store';
import Breadcrumbs from '@/components/breadcrumbs';
import { Card } from '@/components/card';
import { Link } from '@/components/link';
import { pageContentClassName } from '@/utils/class-names';

export default function Home() {
  const { isLoading, isSignedIn, user: { dashboardPath, firstName } = {} } = useUser();
  return (
    <>
      <Breadcrumbs current={{ path: '/' }} />
      <main className={pageContentClassName}>
        {isLoading || import.meta.env.SSR ? (
          ''
        ) : (
          <Card heading="Your Dashboard">
            {!isSignedIn ? (
              <>
                <p className="mb-3 mt-2">Sign in to view your dashboard.</p>
                <Link to={'/sign-in/'} asButton={'green'}>
                  Secure Sign-In
                </Link>
              </>
            ) : dashboardPath ? (
              <>
                <p className="mb-3 mt-2">{`${
                  firstName ? `Welcome ${firstName}!` : ''
                } Get started by opening your dashboard`}</p>
                <Link to={dashboardPath} asButton={'green'}>
                  Open Dashboard
                </Link>
              </>
            ) : (
              <p>Sorry, you do not have a dashboard yet</p>
            )}
          </Card>
        )}
      </main>
    </>
  );
}
